<!--
 * @Author: 月魂
 * @Date: 2021-11-24 10:52:52
 * @LastEditTime: 2022-09-20 16:40:04
 * @LastEditors: 月魂
 * @Description: 美术展列表
 * @FilePath: \silk-road\src\views\exhibition\list.vue
-->
<template>
  <div class="box">
    <van-nav-bar
      :title="$t('exhibition.title')"
      left-arrow
      @click-left="handleBack"
    />
    <!-- <div class="header">
      <div class="title">{{ this.$t('intro.name') }}</div>
      <div class="subTitle">{{ this.$t('exhibition.title') }}</div>
    </div> -->
    <!-- 演出列表 -->
    <div class="list">
      <div class="item" @click="jump(exhibition.id)" v-for="exhibition in this.$t('exhibition.list')" :key="exhibition.id">
        <div class="top">
          <div class="img">
            <img :src="exhibition.imgUrl" alt="" width="100%">
          </div>
          <!-- <div class="badge">线下演出</div> -->
          <div class="des">{{ exhibition.des[0] }}</div>
        </div>
        <div class="bottom">
          <div class="name">{{ exhibition.title }}</div>
          <div class="origin">{{ exhibition.host ? exhibition.host : exhibition.organize }}</div>
          <div class="time">{{ exhibition.time }}</div>
          <div class="address">{{ exhibition.address }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { NavBar } from 'vant'
import { handleBack } from '../../utils/util'
Vue.use(NavBar)

export default {
  name: 'ExhibitionList',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
    this.wxShareDef();
  },
  methods: {
    handleBack() {
      handleBack()
    },
    jump(id) { // 此处需传入剧目id
      this.$router.push(`/exhibition-detail/${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  min-height: 100vh;
  background-image: linear-gradient(to right, #ed6e5a, #b27c9f);
  .header {
    // background-image: url('../assets/index/exhibition.png');
    // background-size: cover;
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px 30px;
    word-break: break-word;
    padding-bottom: 0;
    .title {
      width: 100%;
      font-size: 20px;
      text-align: center;
    }
    .subTitle {
      width: 100%;
      text-align: center;
      font-size: 20px;
    }
  }
  .mySwiper {
    max-width: 1200PX;
    margin: auto;
    .swiper {
      width: 100%;
    }
  }
  // 演出列表
  .list {
    max-width: 1200PX;
    margin: auto;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .item {
      width: 100%;
      padding: 10px;
      background-color: #fff;
      margin-bottom: 10px;
      .top {
        position: relative;
        .img {
          width: 100%;
          img {
            display: block;
          }
        }
        // .badge {
        //   position: absolute;
        //   top: 0;
        //   right: 0;
        //   background-color: crimson;
        //   color: #fff;
        //   text-align: center;
        //   font-size: 12px;
        //   padding: 0 10px;
        // }
        .des {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          padding: 0 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.6);
          color: #fff;
          font-size: 14px;
        }
      }
      .bottom {
        display: flex;
        flex-wrap: wrap;
        .name {
          width: 100%;
          line-height: 2;
          font-size: 16px;
        }
        .origin {
          width: 100%;
          font-size: 14px;
          color: #9a9a9a;
        }
        .time {
          font-size: 12px;
          margin-right: 20px;
          color: #9a9a9a;
        }
        .address {
          font-size: 12px;
          color: #9a9a9a;
        }
      }
    }
    @media (min-width: 1200PX) {
      padding: 0;
      margin-top: 10px;
      .item {
        width: 49%;
      }
    }
  }
}
</style>

